import React, { useEffect, useRef, useState } from "react";

import IsLoadingHOC from "../../components/common/IsLoadingHOC";
import "../../styles/pages/comming-page.css";
import "../../styles/pages/predictive.css"
import { REST_API_METHODS } from "../../utils/constants";
import { callBackendAPI, userInfo } from "../../utils/helpers";

import TitleSetter from "../../components/common/TitleSetter";
import SetApproximateWeekHrs from "../../components/common/SetApproximateWeekHrs";

const PredictiveMaintenance = (props) => {
  const { setLoading } = props;
  const user = userInfo();
  const childRef = useRef();

  const [predictiveMaintenance, setPredictiveMaintenance] = useState([]);

  useEffect(() => {
    getPredictiveMaintenance();
  }, []);

  const getPredictiveMaintenance = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getAssetsGroupWithAssets?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        setPredictiveMaintenance(response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };


  return (
    <div className="predictive-content">
      <TitleSetter title="Predictive Maintenance" />
      <div className="explorewrap services-pg-wrap termscondition predictive-wrap">
        <div className="">
          <div className="assets-configurator-wrap">
            <div className="row align-item-center">
              <div className="clearfix wd100">
                <div className="aboutsimg-div explorebg-image blk">
                  <img className="autoheight" src="images/predective-ban1.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="predictive-types-wrap">
          <div className="container">
            <div className="row rwmn">
              {predictiveMaintenance.map((item, index) => (
                <div key={index} className="col-md-4">
                  <div className="configurator-type">
                    <h4>{item.machine}</h4>
                    <div className="predictive-list">
                      <ul>
                        {item?.assets?.map((asset, aIndex) => (
                          <li key={aIndex}>
                            <a href="javascript:void(0)" onClick={() => childRef.current.viewHandler(asset)} >{asset?.assets?.name} <b>({asset?.serialNo})</b></a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <SetApproximateWeekHrs ref={childRef} setLoading={setLoading} redirect={true} />
    </div>
  )
}

export default IsLoadingHOC(PredictiveMaintenance);