import "./styles/App.css";
import "./styles/index.css";
import "./styles/style.css";
import "./styles/pages/common_media.css";
import "./styles/media.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import AppRouter from "./router";
import { useEffect } from "react";
import { getLocality } from "./utils/helpers";

function App() {
  useEffect(() => {
    if ("caches" in window) {
      console.log('caches clean')
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    localStorage.setItem('country_code', 'in')
    findCurrentLocation();
  }, []);

  const findCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        const location = await getLocality(latitude, longitude);
        localStorage.setItem('country_code', location.countryIsoCode);
      })
    }
  }

  return (
    <div className="App">
      <AppRouter />
      <ToastContainer />
    </div>
  );
}
export default App;
