import React, { useEffect, useState } from "react";
import * as yup from "yup";
import "../../styles/pages/user-management.css";

import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  callBackendAPI,
  callBackendAPIForm
} from "../../utils/helpers";
import {
  Mobile_Max_Length,
  Mobile_Min_Length,
  REST_API_METHODS,
} from "../../utils/constants";
import Error from "../../components/common/Error";
import TitleSetter from "../../components/common/TitleSetter";
import IsLoadingHOC from "../../components/common/IsLoadingHOC";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AutoComplete from "../../components/common/AutoComplete";

const defaultUrl = 'images/camera.png';

const validationSchema = yup
  .object({
    name: yup.string().required(),
    phone: yup
      .string()
      .required("Phone is required"),
    code_phone: yup.string().default(""),
    dial_code: yup.string().default(""),
    email: yup.string().email().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    pin_code: yup.string().required("Pin Code is a required Field"),
    latitude: yup.string(),
    longitude: yup.string(),
  })
  .required();

const UserForm = (props) => {
  const { id } = useParams();
  const { setLoading, isLoading } = props;
  const countryIsoCode = localStorage.getItem('country_code');

  const history = useHistory();
  const [apiError, setApiError] = useState("");
  const [userImage, setUserImage] = useState("");
  const [url, setUrl] = useState('');
  const [countryCode, setCountryCode] = useState(countryIsoCode);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!!id) {
      getUserProfile();
    } else {
      setLoading(false);
    }
  }, []);

  const getUserProfile = async () => {
    try {
      const response = await callBackendAPI(
        `getUserProfile?id=${id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        const data = response.data;
        const phone = data.dialCode + data.phone;
        data.phone = phone;
        data.address.phone = phone;
        setCountryCode(data.countryCode || '');
        Object.entries(data).forEach(([name, value]) => {
          if (name == 'address') {
            setValue("address", value.address);
            setValue("city", value.city);
            setValue("state", value.state);
            setValue("country", value.country);
            setValue("pin_code", value.pin_code);
            setValue("latitude", value.latitude);
            setValue("longitude", value.longitude);
          } else if (name === 'image') {
            setUrl(value);
          } else {
            setValue(name, value);
          }
        });
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const setAddress = (response) => {
    // setValue("address", response.address);
    setValue("city", response.city);
    setValue("state", response.state);
    setValue("country", response.country);
    setValue("pin_code", response.pin_code);
    setValue("latitude", response.latitude);
    setValue("longitude", response.longitude);
  }

  const handlerFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserImage(event.target.files[0]);
      for (const file of event.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // this.svgName = file.name;
          // const extension = file.name.split('.').pop().toLowerCase();
          // this.images.push({ url: e.target.result, extension, name: file.name, checked: false });
          setUrl(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onSubmit = handleSubmit(
    async (values) => {
      setApiError("");
      try {
        setLoading(true);

        // values.phone = values.phone.split(values.dial_code).pop();
        const codeLength = values.dial_code.length;
        values.phone = values.phone.substring(codeLength);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("image", userImage);
        formData.append("address", values.address);
        formData.append("city", values.city);
        formData.append("state", values.state);
        formData.append("country", values.country);
        formData.append("pin_code", values.pin_code);
        formData.append("latitude", values.latitude);
        formData.append("longitude", values.longitude);
        formData.append("code_phone", values.code_phone);
        formData.append("dial_code", values.dial_code);

        let urlPars;

        if (!!id) {
          formData.append("id", id);
          urlPars = 'assignUserUpdate';
        } else {
          urlPars = 'assignUserRegister'
        }
        const response = await callBackendAPIForm(
          urlPars,
          REST_API_METHODS.POST,
          formData
        );
        if (!!response.status) {
          history.goBack();
        } else {
          setApiError(response);
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  return (
    <>
      <TitleSetter title={"New User Register"} />
      <div className="user-management explorewrap termscondition">
        <div className="cartPage-heading">
          <h3>{!!id ? 'Edit' : 'Add'} Sub-User</h3>
          <div className="common_btn">
            {!!id ? <button type="submit" onClick={onSubmit}>
              Update
            </button> : <button type="submit" onClick={onSubmit}>
              Register
            </button>}
          </div>
        </div>
        <div className="container">
          <div className="useform-wrap">
            <div className="formData-wrap common_input_warp">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group imgUploadwrap">
                    <label className="hoverable" htmlFor="fileInput">
                      <img src={url} onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultUrl;
                      }} alt="" />
                      <div className="background"></div>
                    </label>
                    <small>Upload Image</small>
                    <br />
                    <input id="fileInput" type='file' accept=".jpg, .jpeg, .png" onChange={handlerFile} />
                  </div>

                  <div className="form-group">
                    <input
                      {...register("name")}
                      type="text"
                      name="name"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Name"
                    />
                    {!!errors.name && <Error message={errors.name.message} />}
                  </div>
                  <div className="form-group">
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field: { ref, ...field } }) => (
                        <ReactPhoneInput
                          {...field}
                          inputClass="form-control intl-tel-input-custom w-100"
                          inputExtraProps={{
                            ref,
                            required: true,
                            autoFocus: true,
                          }}
                          isValid={(value, country) => {
                            setValue("code_phone", country.iso2);
                            setValue("dial_code", country.dialCode)
                            if (value.match(/12345/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else if (value.match(/1234/)) {
                              return false;
                            } else {
                              return true;
                            }
                          }}
                          country={countryCode}
                          countryCodeEditable={false}
                          specialLabel={"Player Mobile Number"}
                          enableSearch={true}
                        />
                      )}
                    />
                    {!!errors.phone && <Error message={errors.phone.message} />}
                  </div>
                  <div className="form-group">
                    <input
                      {...register("email")}
                      type="email"
                      name="email"
                      autoComplete="off"
                      className="form-control"
                      placeholder="E-mail"
                      disabled={!!id}
                    />
                    {!!errors.email && <Error message={errors.email.message} />}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <AutoComplete setLoading={setLoading} register={register} errors={errors} setAddress={setAddress} />
                  </div>
                  <div className="form-group">
                    <input
                      {...register("city")}
                      className="form-control"
                      type="text"
                      name="city"
                      placeholder="Enter City"
                    />
                    {!!errors.city && <Error message={errors.city.message} />}
                  </div>
                  <div className="form-group">
                    <input
                      {...register("state")}
                      className="form-control"
                      type="text"
                      name="state"
                      placeholder="Enter State"
                    />
                    {!!errors.state && <Error message={errors.state.message} />}
                  </div>
                  <div className="form-group">
                    <input
                      {...register("country")}
                      className="form-control no-edit"
                      type="text"
                      name="country"
                      autoComplete="off"
                      placeholder="Enter Country"
                    />
                    {!!errors.country && (
                      <Error message={errors.country.message} />
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      {...register("pin_code")}
                      className="form-control"
                      type="text"
                      name="pin_code"
                      placeholder="Enter Pincode"
                    />
                    {!!errors.pin_code && (
                      <Error message={errors.pin_code.message} />
                    )}
                  </div>
                </div>
              </div>


              {!!apiError && <Error message={apiError} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(UserForm);
