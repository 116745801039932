import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ImageGallery from "react-image-gallery";
import $ from "jquery";

import { addDays, callBackendAPI, convertDate, showMessage, userInfo } from "../utils/helpers";
import { REST_API_METHODS } from "../utils/constants";
import "../styles/pages/assets-details.css";
import TitleSetter from "../components/common/TitleSetter";
import MaintenanceList from "../components/common/MaintenanceList";
import IsLoadingHOC from "../components/common/IsLoadingHOC";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash } from "@fortawesome/free-solid-svg-icons";

const AssetsDetails = (props) => {
  const { setLoading, isLoading } = props;

  const history = useHistory();
  const user = userInfo();

  let maintenanceId = 0;

  let { id, explore } = JSON.parse(localStorage.getItem("assets-state"));
  const [assetsDetails, setAssetsDetails] = useState({});
  const [assetsImages, setAssetsImages] = useState([]);
  const [serialNumberList, setSerialNumberList] = useState([]);

  const [assetsMaintenance, setAssetsMaintenance] = useState([]);
  const [currentImageUrl, setCurrentImageUrl] = useState(
    "./images/no-image.png"
  );

  useEffect(() => {
    fetchAssetsDetails(id);
    getSerialList();
  }, []);

  const fetchAssetsDetails = async (id) => {
    try {
      setLoading(true);

      const response = await callBackendAPI(
        `getAssetsLinkedDetails?id=${id}`,
        REST_API_METHODS.GET
      );

      if (!!response?.status && !!response.data) {
        const assets = response.data;
        const images = assets?.assets ? assets?.assets.images : 0;
        const index = assets.assets ? assets.assets.defaultImageIndex : 0;
        const updateImages = images.map((a) => {
          return {
            original: a.url,
            thumbnail: a.url,
          };
        });
        setAssetsImages(updateImages);
        setAssetsDetails(assets);
        setAssetsMaintenance(assets?.maintenanceAlerts);
        const defaultUrl = images[index].url;
        setCurrentImageUrl(defaultUrl);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getAssetsLinkedDetailsForExplore = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getAssetsLinkedDetailsForExplore?id=${id}`,
        REST_API_METHODS.GET
      );

      if (!!response?.status && !!response.data) {
        const assets = response.data;
        const images = assets.images;
        const index = assets.defaultImageIndex;
        const updateImages = images.map((a) => {
          return {
            original: a.url,
            thumbnail: a.url,
          };
        });
        setAssetsImages(updateImages);
        setAssetsDetails(assets);
        const defaultUrl = images[index].url;
        setCurrentImageUrl(defaultUrl);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const getSerialList = async () => {
    try {
      const response = await callBackendAPI(
        `getSerialList?userId=${user._id}`,
        REST_API_METHODS.GET
      );

      if (!!response?.status && !!response.data) {
        setSerialNumberList(response.data);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const svgLoad = () => {
    const state = {
      assId: id,
      name: assetsDetails.assets.name,
      svgPath: assetsDetails.assets.svgPath,
    };

    localStorage.setItem("svg-assets", JSON.stringify(state));
    history.push("/svg-model");
  };

  // A function to set the the image in focus that was clicked:
  const setView = (event, index) => {
    setCurrentImageUrl(assetsImages[index].original);
  };

  const requestQuoteHandler = () => {
    history.push({
      pathname: "/request-quote",
      state: {
        id,
      },
    });
  };

  const viewUrl = (url) => {
    if (url) {
      // const urlSplit = url.split('_');
      // const newUrl = urlSplit.pop()
      window.open(url, "_blank").focus();
    }
  };

  const viewAssetsDetailsHandler = (event) => {
    const state = {
      id: event.target.value,
      explore,
    };
    localStorage.setItem("assets-state", JSON.stringify(state));
    history.push("/assets-details/" + state.id);
    fetchAssetsDetails(state.id);
  };

  const removeMaintenanceAlert = async (maintenanceId) => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `removeMaintenanceAlert?id=${assetsDetails._id}&userId=${assetsDetails.user}&maintenanceId=${maintenanceId}`,
        REST_API_METHODS.DELETE
      );
      if (!!response?.status) {
        modalHandler(maintenanceId, 'hide');
        showMessage(response.message);
        fetchAssetsDetails(assetsDetails._id);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  const modalHandler = (id, event) => {
    maintenanceId = id;
    setLoading(false);
    $("#closeAlert").modal(event);
  }

  return (
    <>
      <TitleSetter title={"Assets Details"} />
      <div className="maincontentwrap">
        <div className="myprofilewraptab asstesdetailswrap detail-assets-lt">
          <div className="tile" id="tile-1">
            {/* Tab panes */}
            <div className="tabs-content-wrap">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="container">
                    {!isLoading && !!Object.keys(assetsDetails).length && (
                      <div className="row app">
                        <div className="col-md-6">
                          <div className="magnify-Image">
                            {/* Component that will show the image with zoom functionality */}
                            <SideBySideMagnifier
                              alwaysInPlace={false}
                              className="image-to-magnify"
                              fillAvailableSpace={false}
                              imageSrc={currentImageUrl}
                              largeImageSrc={currentImageUrl} // Optional
                            />
                            {/* Component that will show Images thumbnails in a slider */}
                            <ImageGallery
                              items={assetsImages}
                              onThumbnailClick={setView}
                              showFullscreenButton={false}
                              showPlayButton={false}
                              showNav={false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="serial-no-box">
                            <div className="serial-no-dropdown ">
                              {/* <label htmlFor="">Choose Serial No:</label> */}
                              <select
                                className="form-control"
                                onChange={viewAssetsDetailsHandler}
                                value={id}
                                style={{
                                  backgroundImage: `url(${process.env.PUBLIC_URL +
                                    "/images/down_arrow.png"
                                    })`,
                                }}
                              >
                                {serialNumberList
                                  .filter(
                                    (a) =>
                                      a?.assets?.name ==
                                      assetsDetails?.assets?.name
                                  )
                                  .map((serial, index) => (
                                    <option key={index} value={serial._id}>
                                      {serial.serialNo}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="zoomercontent">
                            <h2 className="text-capitalize">
                              {assetsDetails?.assets?.name ||
                                assetsDetails.name}
                            </h2>
                            {/* <p className="text-muted">
                            {assetsDetails?.assets?.description ||
                              assetsDetails.description}
                          </p> */}

                            {explore && (
                              <div className="assetsdetailsitm assets-items-box text-capitalize">
                                <p>
                                  <b>Serial No:</b>{" "}
                                  <span className="text-muted">
                                    {assetsDetails?.serialNo || "--"}
                                  </span>
                                </p>
                                <p>
                                  <b>Address:</b>{" "}
                                  <span className="text-muted">
                                    {assetsDetails?.address?.address},{" "}
                                    {assetsDetails?.address?.city},{" "}
                                    {assetsDetails?.address?.pin_code}
                                  </span>
                                </p>
                                <p>
                                  <b>Date of installations:</b>{" "}
                                  <span className="text-muted">
                                    {convertDate(
                                      assetsDetails?.assetsInstallDate
                                    )}
                                  </span>
                                </p>
                                <p>
                                  <b>Warranty expiration:</b>{" "}
                                  <span className="text-muted">
                                    {convertDate(addDays(assetsDetails?.assetsInstallDate, assetsDetails?.warrantyExpiration))}
                                  </span>
                                </p>
                                <p>
                                  <b>Running days:</b>{" "}
                                  <span className="text-muted">
                                    {assetsDetails?.runningDays}
                                  </span>
                                </p>
                              </div>
                            )}
                            <div className="pdfdownloadbtn">
                              <div className="common_btn flex-div">
                                {assetsDetails?.assets?.userManual && (
                                  <button
                                    className="mr-2"
                                    onClick={() =>
                                      viewUrl(assetsDetails?.assets?.userManual)
                                    }
                                  >
                                    Operations Manual
                                  </button>
                                )}
                                {assetsDetails?.dataBook && (
                                  <button
                                    className="mr-2"
                                    onClick={() =>
                                      viewUrl(assetsDetails?.dataBook)
                                    }
                                  >
                                    Product Data Book
                                  </button>
                                )}
                                {assetsDetails?.assets?.brochure && (
                                  <button
                                    className="mr-2"
                                    onClick={() =>
                                      viewUrl(assetsDetails?.assets?.brochure)
                                    }
                                  >
                                    Product Brochure
                                  </button>
                                )}

                                {assetsDetails?.assets?.technicalDrawings && (
                                  <button
                                    className="mr-2"
                                    onClick={() =>
                                      viewUrl(assetsDetails?.assets?.technicalDrawings)
                                    }
                                  >
                                    Technical Drawings
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!!assetsMaintenance.length && <div className="maintenance-alerts-wrap">
              <p className="sm-heading">Maintenance Alerts</p>
              <MaintenanceList
                alerts={assetsMaintenance}
                user={assetsDetails?.user}
                id={assetsDetails._id}
                modalHandler={modalHandler}
              />
            </div>}
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-center"
        id="closeAlert"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      >
        <div className="modal-dialog custom_modal_wrap" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="logoutpopupwrap">
                <h2 className="modlheading">Mark Complete</h2>
                <FontAwesomeIcon
                  className="w-100 h1 fonticon"
                  color={"red"}
                  icon={faBellSlash}
                />
                <p className="mt-4 black-text">Are you sure you want to complete?</p>
                <div className="common_btn">
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={() => removeMaintenanceAlert(maintenanceId)}
                  >
                    Yes
                  </button>
                  <button type="button" data-dismiss="modal">
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(AssetsDetails);
