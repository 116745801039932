import React, { useEffect, useState } from "react";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { callBackendAPI, showError, showMessage } from "../utils/helpers";
import {
  Mobile_Max_Length,
  Mobile_Min_Length,
  REST_API_METHODS,
} from "../utils/constants";
import _, { min } from "lodash";
import Error from "../components/common/Error";
import countriesList from "../country_list/countries.json";
import "../styles/pages/add-address.css";
import IsLoadingHOC from "../components/common/IsLoadingHOC";
import "react-intl-tel-input/dist/main.css";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AutoComplete from "../components/common/AutoComplete";

const validationSchema = yup
  .object({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    phone: yup
      .string()
      .required("Phone is required")
      .min(Mobile_Min_Length)
      .max(Mobile_Max_Length),
    code_phone: yup.string().default(""),
    dial_code: yup.string().default(""),
    pin_code: yup.string().required("Pin Code is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    address: yup.string().required("Address is required"),
    address_type: yup
      .string()
      .required("")
      .typeError("Please select an Address Type"),
    default_address: yup.bool(),
  })
  .required();

const AddAddress = (props) => {
  const { setLoading, show } = props;
  const [countries] = useState(countriesList.countries);
  const isEdit = props.editId;
  const countryIsoCode = localStorage.getItem('country_code');
  const [countryCode, setCountryCode] = useState(countryIsoCode);

  const {
    register,
    setValue,
    control,
    resetField,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    resetForm();
  }, []);

  useEffect(() => {
    resetForm();
    if (isEdit) {
      getAddressById();
    } else {
      setLoading(false);
    }
  }, [isEdit]);

  const resetForm = () => {
    resetField("first_name");
    resetField("last_name");
    resetField("phone");
    resetField("pin_code");
    resetField("city");
    resetField("state");
    resetField("country");
    resetField("address");
    resetField("address_type");
    resetField("default_address");
    clearErrors();
  };

  const getAddressById = async () => {
    try {
      setLoading(true);
      const response = await callBackendAPI(
        `getAddressById?id=${isEdit}`,
        REST_API_METHODS.GET
      );
      if (!!response.status) {
        const address = response.data;
        address.phone = address.dial_code + address.phone;
        setCountryCode(address.code_phone);
        Object.entries(address).forEach(([key, value]) => {
          setValue(key, value)
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const onSubmit = handleSubmit(
    async (values) => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        values.userId = user._id;
        // values.phone = values.phone.split(values.dial_code).pop();
        const codeLength = values.dial_code.length;
        values.phone = values.phone.substring(codeLength);
        const params = {
          path: "newAddress",
          method: REST_API_METHODS.POST,
          payload: values,
        };
        if (!!isEdit) {
          params.path = "updateAddress";
          params.method = REST_API_METHODS.PUT;
          params.payload.id = isEdit;
        }
        setLoading(true);
        const response = await callBackendAPI(
          params.path,
          params.method,
          _.omit(params.payload, "_id", "create_for", "status", "createdAt")
        );
        if (!!response.status) {
          showMessage(response.message);
          props.closeModal();
          // history.push("/my-profile");
        } else if (!response.status) {
          showError(response.message);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    },
    (errors) => {
      console.log("errors", errors);
    }
  );

  const onChangeNumber = (event) => {
    console.log("event for chnage nu", event);
  };

  const onBlurNumber = (event) => {
    console.log("event for blur", event);
  };

  const setAddress = (response) => {
    // setValue("address", response.address);
    setValue("city", response.city);
    setValue("state", response.state);
    setValue("country", response.country);
    setValue("pin_code", response.pin_code);
    setValue("latitude", response.latitude);
    setValue("longitude", response.longitude);
  }

  return (
    <>
      <div className="setting_wrap">
        {/* <h2>{isEdit ? "Edit" : "Add New"} Address</h2> */}
        <div className="addaddresswrap">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("first_name")}
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="first_name"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.first_name && (
                        <Error message={errors.first_name.message} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("last_name")}
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="last_name"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.last_name && (
                        <Error message={errors.last_name.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                      <ReactPhoneInput
                        {...field}
                        inputClass="form-control intl-tel-input-custom w-100"
                        inputExtraProps={{
                          ref,
                          required: true,
                          autoFocus: true,
                        }}
                        isValid={(value, country) => {
                          setValue("code_phone", country.iso2);
                          setValue("dial_code", country.dialCode)
                          if (value.match(/12345/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        country={countryCode}
                        countryCodeEditable={false}
                        specialLabel={"Player Mobile Number"}
                        enableSearch={true}
                      />
                    )}
                  />
                  {!!errors.phone && <Error message={errors.phone.message} />}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="common_input_warp">
                    <div className="form-group mt-4">
                      <AutoComplete setLoading={setLoading} register={register} errors={errors} setAddress={setAddress} show={show} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("city")}
                        type="text"
                        className="form-control"
                        placeholder="Enter city"
                        name="city"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.city && <Error message={errors.city.message} />}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("state")}
                        type="text"
                        className="form-control"
                        placeholder="Enter state"
                        name="state"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.state && <Error message={errors.state.message} />}
                    </div>
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("country")}
                        type="text"
                        className="form-control no-edit" //class to replace readOnly property
                        placeholder="Enter country"
                        name="country"
                        autoComplete="off"
                        required=""
                        
                      />
                      {!!errors.country && <Error message={errors.country.message} />}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("pin_code")}
                        type="text"
                        className="form-control"
                        placeholder="Postal/ PinCode"
                        name="pin_code"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.pin_code && (
                        <Error message={errors.pin_code.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                
                </div>
                <div className="col-md-6">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <input
                        {...register("state")}
                        type="text"
                        className="form-control"
                        placeholder="Enter state"
                        name="state"
                        autoComplete="off"
                        required=""
                      />
                      {!!errors.state && (
                        <Error message={errors.state.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="common_input_warp">
                    <div className="form-group">
                      <textarea
                        {...register("address")}
                        className="form-control"
                        name="address"
                        id="address"
                        cols="30"
                        rows="10"
                        placeholder="Address"
                      ></textarea>
                      {!!errors.address && (
                        <Error message={errors.address.message} />
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  <div className="saveaddress">
                    <div className="radio_btn_wrap">
                      <p>
                        <input
                          {...register("address_type")}
                          type="radio"
                          id="address1"
                          name="address_type"
                          value="1"
                        />
                        <label htmlFor="address1">Home</label>
                      </p>
                      <p>
                        <input
                          {...register("address_type")}
                          type="radio"
                          id="address2"
                          name="address_type"
                          value="2"
                        />
                        <label htmlFor="address2">Office</label>
                      </p>
                      <p>
                        <input
                          {...register("address_type")}
                          type="radio"
                          id="address3"
                          name="address_type"
                          value="3"
                        />
                        <label htmlFor="address3">Others</label>
                      </p>
                    </div>
                    {!!errors.address_type && (
                      <Error message={errors.address_type.message} />
                    )}
                  </div>
                </div>
              </div>
              <div className="customimputcheckbox">
                <div className="form-group">
                  <input
                    {...register("default_address")}
                    type="checkbox"
                    id="custom_table"
                  />
                  <label htmlFor="custom_table">
                    Make this Default Address
                  </label>
                </div>
              </div>
              <div className="common_btn">
                <button onClick={onSubmit}>
                  <i className="fas fa-plus"></i>
                  {isEdit ? "Update" : "Add New"} Address
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsLoadingHOC(AddAddress);
