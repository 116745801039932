import React, { useState } from "react";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faCrown } from "@fortawesome/free-solid-svg-icons";
import "../../styles/pages/header.css";
import $ from "jquery";
import { useEffect } from "react";
// import { onMessageListener } from "../../utils/firebase";
import { callBackendAPI, userInfo } from "../../utils/helpers";
import { REST_API_METHODS } from "../../utils/constants";
import { usePrime } from "../../hooks";
import { viewSupport } from "../../utils/helpers";

let intervalId;
const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const user = userInfo();

  const [messageCount, setMessageCount] = useState(0);
  const [maintenanceCount, setMaintenanceCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const { isPrime } = usePrime();

  useEffect(() => {
    $(".menuBtn").on('click', function () {
      $(this).toggleClass("act");
      $(".mainMenu").toggleClass("act");
      // if ($(this).hasClass("act")) {
      //   alert('dsfsgfd');
      //   $(".mainMenu").removeClass("act");
      // } else {
      //   $(".mainMenu").addClass("act");
      // }
    });
    $(".closenav").on("click", function () {
      $(".mainMenu").removeClass("act");
      $(".menuBtn").removeClass("act");
    });

    $('.navbar-brand').on("click", function () {
      $(".mainMenu").removeClass("act");
      $(".menuBtn").removeClass("act");
    });

    $('.close_overlay').on("click", function () {
      $(".mainMenu").removeClass("act");
      $(".menuBtn").removeClass("act");
    });


    $(".userdetails .dropdown-menu a").on("click", function () {
      $(".mainMenu").removeClass("act");
      // $(".menuBtn").removeClass("act");
    });

    $('#nav-bar').on('click', 'li', function () {
      $('#innerNavMenus li.active').removeClass('active');
    });

    getNotificationsCount();
    if (!!intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
      getNotificationsCount();
      // "Message to alert every 3 seconds"
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getNotificationsCount = async () => {
    try {
      const response = await callBackendAPI(
        `getNotificationCount?userId=${user._id}`,
        REST_API_METHODS.GET
      );
      if (!!response.status && !!response.data) {
        setCount(response.data.notificationCount, "update");
        setMaintenanceCount(response.data.maintenanceCount);
        setCartCount(response.data.cartCount);
      } else {
        setCount(0, "update");
        setMaintenanceCount(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setCount = (counts, event) => {
    let count = messageCount;
    count += counts;
    if (event === "update") {
      count = counts;
    }
    setMessageCount(count);
  };

  const checkCurrentRoute = (linkPath) =>
    linkPath === location.pathname && handleMenuToggle();

  const handleMenuToggle = () => {
    const elementIds = ["dropdown-toggle", "dropdown-menu"];

    const isOpen = elementIds.every((id) =>
      document.getElementById(id)?.classList.contains("show")
    );

    elementIds.forEach((id) => {
      if (isOpen) {
        document.getElementById(id)?.classList.remove("show");
      } else {
        document.getElementById(id)?.classList.add("show");
      }
      document.getElementById(id).addEventListener("click", (event) => {
        //add click event to all of them
        if (event.target.parentElement.id !== "toggleprofile") {
          document.getElementById("dropdown-toggle")?.classList.remove("show");
          document.getElementById("dropdown-toggle")?.classList.add("hide");
          document.getElementById("dropdown-menu")?.classList.remove("show");
          document.getElementById("dropdown-menu")?.classList.add("hide");
          //add class
        } else {
          // document.getElementById('dropdown-menu')?.classList.toggle("hide")
        }
      });
    });
  };

  const toggleLogout = (toggleMenu) => {
    const modalElement = document.getElementById("logoutpopup");
    const isModalOpen = document
      .getElementById("logoutpopup")
      .classList.contains("show");

    if (isModalOpen) {
      document.body.classList.remove("modal-open");
      modalElement?.classList.remove("show");
      modalElement.style.display = "none";
    } else {
      document.body.classList.add("modal-open");
      modalElement?.classList.add("show");
      modalElement.style.display = "block";
    }
    toggleMenu && handleMenuToggle();
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <>
      <div className="headerwap">
        <nav className="navbar navbar-expand-lg fixed-top">
          <Link className="navbar-brand" to={"/"}>
            <img src="images/logo_ergin.jpg" alt="" />
          </Link>
          {/* <span id="mobsidemenu" className="navbar-toggler-icon">
            <i className="fa">
              <FontAwesomeIcon icon={faBars} />
            </i>
          </span> */}
          <a className="menuBtn">
            <span className="lines"></span>
          </a>
          <ul id="nav-bar" className="navbar-nav navmenuwrap text-right ">
            {/* <li className="nav-item">
                <NavLink exact activeClassName="active" to={"/"}>
                  Home
                </NavLink>
              </li> */}
            {/* <li className="nav-item">
              <NavLink exact activeClassName="active" to={"/services"}>
                Services
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                exact
                activeClassName="active"
                to={"/maintenance-alert"}
              >
                Maintenance Alerts
                {!!maintenanceCount && (
                  <span className="blink_me"> {maintenanceCount}</span>
                )}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                activeClassName="active"
                to={"/submitted-request"}
                id="submitted-request"
              >
                My Submitted Requests
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" to={"/explore"}>
                Explore
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" to={"/about-us"}>
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact activeClassName="active" to={"/contact-us"}>
                Contact us
              </NavLink>
            </li>

            <li className="nav-item svgicon">
              {!!cartCount && (
                <span className="bell-count cart">{cartCount}</span>
              )}
              <NavLink exact activeClassName="active" to={"/cart"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                >
                  <g
                    id="Group_407"
                    data-name="Group 407"
                    transform="translate(-1000 -16)"
                  >
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="20"
                      cy="20"
                      r="20"
                      transform="translate(1000 16)"
                      fill="#ededed"
                    />
                    <g
                      id="Group_1"
                      data-name="Group 1"
                      transform="translate(1009.023 24.805)"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M0,0H21.967V21.967H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M3.725,6.02.757,3.052l1.3-1.3L5.02,4.725H18.971A.915.915,0,0,1,19.847,5.9l-2.2,7.322a.915.915,0,0,1-.877.653H5.556v1.831H15.624v1.831H4.641a.915.915,0,0,1-.915-.915Zm1.831.536v5.492H16.093L17.74,6.556ZM5.1,21.2a1.373,1.373,0,1,1,1.373-1.373A1.373,1.373,0,0,1,5.1,21.2Zm10.984,0a1.373,1.373,0,1,1,1.373-1.373A1.373,1.373,0,0,1,16.082,21.2Z"
                        transform="translate(-0.064 -0.149)"
                      />
                    </g>
                  </g>
                </svg>

                {/* <FontAwesomeIcon icon={faShoppingCart} /> */}
              </NavLink>
            </li>

            <li className="nav-item svgicon">
              {!!messageCount && (
                <span className="bell-count">{messageCount}</span>
              )}
              <NavLink
                exact
                activeClassName="active"
                to={"/notification"}
                onClick={() => setMessageCount(0)}
              >
                {/* <FontAwesomeIcon icon={faBell} /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                >
                  <g
                    id="Group_408"
                    data-name="Group 408"
                    transform="translate(-1067 -15)"
                  >
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="20"
                      cy="20"
                      r="20"
                      transform="translate(1067 15)"
                      fill="#ededed"
                    />
                    <g
                      id="Group_2"
                      data-name="Group 2"
                      transform="translate(1076.603 23.805)"
                    >
                      <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M0,0H21.967V21.967H0Z"
                        fill="none"
                      />
                      <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M18.475,15.729h1.831V17.56H2V15.729H3.831V9.322a7.322,7.322,0,1,1,14.645,0Zm-1.831,0V9.322a5.492,5.492,0,1,0-10.984,0v6.407ZM8.407,19.391H13.9v1.831H8.407Z"
                        transform="translate(-0.169 -0.169)"
                      />
                    </g>
                  </g>
                </svg>
              </NavLink>
            </li>
            <li
              id="dropdown-toggle"
              className="nav-item userdetails log_out_popup"
            >
              <div className="notifymob">
                {/* <span className="bell-count">10</span> */}

                <NavLink exact activeClassName="active" className="closenav" to={"/cart"}>
                  {!!cartCount && (
                    <span className="bell-count cart">{cartCount}</span>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                  >
                    <g
                      id="Group_407"
                      data-name="Group 407"
                      transform="translate(-1000 -16)"
                    >
                      <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="20"
                        cy="20"
                        r="20"
                        transform="translate(1000 16)"
                        fill="#ededed"
                      />
                      <g
                        id="Group_1"
                        data-name="Group 1"
                        transform="translate(1009.023 24.805)"
                      >
                        <path
                          id="Path_4"
                          data-name="Path 4"
                          d="M0,0H21.967V21.967H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_5"
                          data-name="Path 5"
                          d="M3.725,6.02.757,3.052l1.3-1.3L5.02,4.725H18.971A.915.915,0,0,1,19.847,5.9l-2.2,7.322a.915.915,0,0,1-.877.653H5.556v1.831H15.624v1.831H4.641a.915.915,0,0,1-.915-.915Zm1.831.536v5.492H16.093L17.74,6.556ZM5.1,21.2a1.373,1.373,0,1,1,1.373-1.373A1.373,1.373,0,0,1,5.1,21.2Zm10.984,0a1.373,1.373,0,1,1,1.373-1.373A1.373,1.373,0,0,1,16.082,21.2Z"
                          transform="translate(-0.064 -0.149)"
                        />
                      </g>
                    </g>
                  </svg>
                </NavLink>
                <NavLink
                  className="closenav"
                  exact
                  activeClassName="active"
                  to={"/notification"}
                  onClick={() => setMessageCount(0)}
                >
                  {/* <FontAwesomeIcon icon={faBell} /> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                  >
                    <g
                      id="Group_408"
                      data-name="Group 408"
                      transform="translate(-1067 -15)"
                    >
                      <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="20"
                        cy="20"
                        r="20"
                        transform="translate(1067 15)"
                        fill="#ededed"
                      />
                      <g
                        id="Group_2"
                        data-name="Group 2"
                        transform="translate(1076.603 23.805)"
                      >
                        <path
                          id="Path_6"
                          data-name="Path 6"
                          d="M0,0H21.967V21.967H0Z"
                          fill="none"
                        />
                        <path
                          id="Path_7"
                          data-name="Path 7"
                          d="M18.475,15.729h1.831V17.56H2V15.729H3.831V9.322a7.322,7.322,0,1,1,14.645,0Zm-1.831,0V9.322a5.492,5.492,0,1,0-10.984,0v6.407ZM8.407,19.391H13.9v1.831H8.407Z"
                          transform="translate(-0.169 -0.169)"
                        />
                      </g>
                    </g>
                  </svg>
                </NavLink>
              </div>
              <a
                className="dropdown-toggle nav-link"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                href="javascript:void(0)"
                onClick={handleMenuToggle}
              >
                <img src={user?.image || "images/logo_ergin.png"} alt="" />
                {isPrime && (
                  <div className="star-user">
                    <FontAwesomeIcon icon={faCrown} />
                  </div>
                )}
                <p>&nbsp;{user?.name}</p>

                <i className="fa">
                  <FontAwesomeIcon className="fa" icon={faChevronDown} />
                </i>
              </a>
              <div
                id="dropdown-menu"
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link
                  to={"/my-profile"}
                  onClick={() => checkCurrentRoute("/my-profile")}
                >
                  <a className="dropdown-item close_overlay" href="#">
                    <img src="images/profileicon.png" alt="" />
                    My Profile
                  </a>
                </Link>

                <Link
                  className="dropdown-item close_overlay"
                  to={"/raise-ticket/0/new"}
                  onClick={() => checkCurrentRoute("/raise-ticket/0")}
                >
                  <img src="images/raiseicon.png" alt="" />
                  Raise Ticket
                </Link>

                <Link
                  to={"/settings"}
                  onClick={() => checkCurrentRoute("/settings")}
                >
                  <a className="dropdown-item close_overlay" href="#">
                    <img src="images/settingicon.png" alt="" />
                    Settings
                  </a>
                </Link>
                {!!user && user?.role?.[0]?.type !== "assign_user" && (
                  <Link
                    to={"/user-management"}
                    onClick={() => checkCurrentRoute("/user-management")}
                  >
                    <a className="dropdown-item close_overlay" href="#">
                      <img src="images/group.png" alt="" />
                      Sub-Users
                    </a>
                  </Link>
                )}
                <a
                  className="dropdown-item close_overlay"
                  href="javascript:void(0)"
                  onClick={() => toggleLogout(true)}
                >
                  <img src="images/logouticon.png" alt="" />
                  Logout
                </a>
              </div>
              <div className="overlay"></div>
            </li>
          </ul>
        </nav>
      </div>
      <nav className="mainMenu">
        <ul>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/"}>
              Home
            </NavLink>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/about-us"}>
              About
            </NavLink>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/maintenance-alert"}>
              Maintenance Alerts
              {!!maintenanceCount && (
                <span className="blink_me"> {maintenanceCount}</span>
              )}
            </NavLink>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/submitted-request"}>
              My Submitted Requests
            </NavLink>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/explore"}>
              Explore
            </NavLink>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/assets-list"}>
              Linked Assets
            </NavLink>
          </li>
          <li className="closenav">
            <a href='javascript:void(0)' onClick={viewSupport}>
              Technical Support
            </a>
          </li>
          <li className="closenav">
            <NavLink exact activeClassName="active" to={'/location'}>
              Geo Location
            </NavLink>
          </li>
          {!!user?.configuratorMain &&
            <li className="closenav">
              <NavLink exact activeClassName="active" to={'/categories-assets-groups'}>Asset Configurator</NavLink>
            </li>
          }
          {!!user?.predictiveMain &&
            <li className="closenav">
              <NavLink exact activeClassName="active" to={'/predictive-maintenance'}>Predictive Maintenance</NavLink>
            </li>
          }
          <li className="closenav">
            <NavLink exact activeClassName="active" to={"/contact-us"}>
              Contact us
            </NavLink>
          </li>

        </ul>
      </nav>
      <div
        className="modal fade modal-center"
        id="logoutpopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
      >
        <div className="modal-dialog custom_modal_wrap" role="document">
          <div className="modal-content">
            <div className="modal-body">
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => toggleLogout(false)}
              >
              &times;
              </button> */}
              <div className="logoutpopupwrap">
                <div className="like_img">
                  <img src="images/logout_icon.png" alt="" />
                </div>
                <h2>Logout</h2>
                <p>Are you sure you want to logout?</p>
                <div className="common_btn">
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={handleLogout}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={() => toggleLogout(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
